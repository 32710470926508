import { Helmet } from 'react-helmet';
import GA  from 'ganalytics';

import data from './data.json';
import Heroized from './components/heroized';
import Projects from './components/projects';
import './style';

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
	const ga = new GA(process.env.GOOGLE_ANALYTICS);
	ga.send('pageview');
}

const currentPosition = data.experience.moonwalker;
const pageTitle = `${data.name} - ${currentPosition.role} @ ${currentPosition.org }`;
const summary = data.summary || pageTitle;

const App = () => (
	<article className="hresume">
		<Helmet>
			<title>{pageTitle}</title>
			<meta name="description" content={summary} />
		</Helmet>
		<p className="summary">
			{summary}
		</p>
		<div className="contact vcard">
			<h1 className="fn">
				{data.name}
			</h1>
			<dl>
				<dt>Nickname:</dt>
				<dd className="nickname">{data.nickname}</dd>
				<dt>Organization:</dt>
				<dd className="org">{currentPosition.org}</dd>
				<dt>Email:</dt>
				<dd className="email">{data.email}</dd>
			</dl>
		</div>
		<div className="skills">
			<h2>Skills</h2>
			<ul>
				{data.skills.map((skill) => (
					<li key={skill}>
						{skill}
					</li>
				))}
			</ul>
		</div>
		<Heroized />
		<Projects />
		<div className="social">
			<h2>Social links</h2>
			<ul>
				{Object.entries(data.social).map(([key, item]) => (
					<li key={key} className={key.lowercase}>
						<a href={item} rel="external">{key}</a>
					</li>
				))}
			</ul>
		</div>
	</article>
);

export default App;
