import heroizedUrl from '../assets/channeler.png';
import data from '../data.json';
import css from './heroized.module.css';

const Heroized = () => (
	<div className={css.root}>
		<figure>
			<img alt={data.heroized.title} src={heroizedUrl} width="256" height="256" />
			<figcaption>
				<em>{data.heroized.title}</em>
				{` by `}
				<a href={data.heroized.url} rel="external">
					{data.heroized.author}
				</a>
			</figcaption>
		</figure>
		<p dangerouslySetInnerHTML={{ __html: data.heroized.content }} />
	</div>
);

export default Heroized;
