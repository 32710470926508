import data from '../data.json';

import css from './projects.module.css';

const Projects = () => (
	<div className={css.root}>
		<h2 className={css.title}>Projects</h2>
		<div className={css.items}>
			{data.projects.map((project) => (
				<a
					className={css.item}
					key={project.title}
					href={project.url}
					rel="external"
				>
					<h3 className={css.itemTitle}>{project.title}</h3>
					<p className={css.itemDescription}>{project.description}</p>
				</a>
			))}
		</div>
	</div>
);

export default Projects;
